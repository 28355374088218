<template>
    <div class="lg:w-3/4">
        <div class="site-content max-w-md">
            <article
                id="post-6541"
                class="post-6541 page type-page status-publish hentry"
            >
                <div class="entry-content">
                    <h2><b>How to Get Help</b></h2>
                    <p>
                        <span style="font-weight: 400"
                            >If you have a question or need technical support,
                            there are several ways to get help:</span
                        >
                    </p>
                    <p>
                        <span style="font-weight: 400">Call: </span>
                        <a
                            href="callto:480-618-5121"
                            class="font-semibold text-primary hover:underline"
                            >480-618-5121.</a
                        >
                    </p>
                    <ul>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Friday, 10/30 4 p.m. – 6 p.m. Eastern</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Monday, 11/2&nbsp; 3 p.m. – 6 p.m.
                                Eastern</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Tuesday, 11/3 3 p.m. – 6 p.m. Eastern</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Wednesday, 11/4 3 p.m. – 6 p.m. Eastern</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Thursday, 11/5 9 a.m. – 5:30 p.m. Eastern</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Friday, 11/6 9 a.m. – 5:30 p.m. Eastern</span
                            >
                        </li>
                    </ul>
                    <p>
                        <span style="font-weight: 400"
                            >If you are in the NAES Biennial Conference
                            2020&nbsp; and have questions or are experiencing
                            problems, you can chat with a support person. Simply
                            click on the <strong> chat icon </strong> in the
                            bottom right corner of your screen.</span
                        >
                    </p>
                    <p>
                        <span style="font-weight: 400"
                            >You can access our virtual Help Desk. Here you can
                            speak with a staff member who can help you with your
                            tech setup, answer questions about the meeting, and
                            more. To visit the Help Desk, check the
                            <a class="font-bold" href="/sessions">schedule</a
                            >!</span
                        >
                    </p>
                    <p>
                        You can email directly:
                        <a
                            class="font-bold"
                            href="mailto:biennialhelp@episcopalschools.org"
                            >biennialhelp@episcopalschools.org</a
                        >
                    </p>
                    <h2><b>Logging In</b></h2>
                    <p>
                        <span style="font-weight: 400"
                            >Since you are accessing these FAQs from within the
                            conference platform, we assume you were able to log
                            in successfully.</span
                        >
                    </p>
                    <ul>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Can someone else log in for me?</span
                            >
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >No, your login information is specific
                                        to you and should be kept private. For
                                        security purposes, you can only be
                                        logged in from 1 location at a
                                        time.</span
                                    >
                                </li>
                            </ul>
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Can I access the platform before the event
                                begins to confirm my equipment will work?</span
                            >
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Yes, attendees will be given access to
                                        the virtual event platform beginning
                                        Wednesday, 10/27/2020.</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >A Help Desk meeting room will be
                                        available. Visit the
                                        <a class="font-bold" href="/sessions"
                                            >“PROGRAM”</a
                                        >
                                        tab to access.</span
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h2><b>Troubleshooting</b></h2>
                    <p>
                        <span style="font-weight: 400"
                            >How do I get help if I am having technical
                            issues?</span
                        >
                    </p>
                    <ul>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >There are several ways to get help if you are
                                having issues with the site.</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Click on the “HELP” tab on the top menu bar
                                (this page).</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Click on the help icon on the bottom right
                                corner or your screen to chat live.</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400">Call: </span>
                            <a
                                href="callto:480-618-5121"
                                class="font-semibold text-primary hover:underline"
                                >480-618-5121.</a
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Live technical support is available the days of
                                the conference from 9:00 a.m. – 5:30 a.m.</span
                            >
                        </li>
                    </ul>
                    <h2><b>What should I do if…</b></h2>
                    <ul>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >I cannot access a workshop?&nbsp;</span
                            >
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Please click on the help icon on the
                                        bottom right of your screen to chat live
                                        or go to the Help Desk</span
                                    >
                                </li>
                            </ul>
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >I can’t hear/see other attendees in a workshop
                                or they can’t hear/see me?&nbsp;</span
                            >
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Please click on the help icon on the
                                        bottom right of your screen to chat live
                                        or go to the Help Desk.</span
                                    >
                                </li>
                            </ul>
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >My audio/video is buffering.</span
                            >
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Check the speed on your internet
                                        connection</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Try turning off your camera. A camera
                                        can consume a lot of bandwidth</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Close all other applications running on
                                        your device.</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Make sure that your device is as close
                                        to the router as possible. If possible,
                                        use a hard-wired connection</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Click on the help icon on the bottom
                                        right of your screen to chat live.</span
                                    >
                                </li>
                            </ul>
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >I hear an echo when I talk or from other
                                attendees.</span
                            >
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Try muting and then unmuting.</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >If possible, do not use the built-in
                                        microphone and speakers on your laptop.
                                        Use an external headset or
                                        speaker.</span
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h2><b>Exhibition</b></h2>
                    <p><b>Where can I find exhibitor information?</b></p>
                    <p>
                        <span style="font-weight: 400"
                            >Click on the
                            <a class="font-bold" href="/tradeshow">
                                ‘EXHIBITS’
                            </a>
                            tab in the top menu to view additional information
                            and connect with the exhibitors.</span
                        >
                    </p>
                    <h2><b>Communicating with other participants</b></h2>
                    <p><b>Where can I see who is attending?</b></p>
                    <p>
                        <span style="font-weight: 400"
                            >Click on the
                            <a class="font-bold" href="/attendees">
                                ‘ATTENDEES’
                            </a>
                            tab on the top menu bar. You can search for
                            participants by first name, last name or
                            organisation, and you can filter by country.</span
                        >
                    </p>
                    <h2><b>Are the sessions being recorded?</b></h2>
                    <p>
                        <span style="font-weight: 400"
                            >Yes. All live sessions and plenaries in the program
                            will be recorded. They will be available in the
                            ‘On-demand’ section of the Program within a day of
                            their broadcast.</span
                        >
                    </p>
                    <h2>
                        <b>Can I contact or message other participants?</b>
                    </h2>
                    <p>
                        <span style="font-weight: 400"
                            >Yes, you can search for a participant by first
                            name, last name, or institution under the
                            ‘ATTENDEES’ tab on the top menu bar. Then click on
                            the ‘Send a Message’ icon.</span
                        >
                    </p>
                    <h1><b>Best Practices For Meeting Participation</b></h1>
                    <h2><b>Equipment</b></h2>
                    <h3>General requirements</h3>
                    <ul>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >You will need access to either a PC, Mac,
                                tablet, or smartphone. A PC or Mac is
                                recommended. To participate in the meetings, it
                                is recommended that you have an internal or
                                external camera so that you can be seen on
                                video.</span
                            >
                        </li>
                    </ul>
                    <h3>Microphones</h3>
                    <ul>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Clear audio can make a big difference.
                                Laptop/phone audio can work, but audio through
                                an external microphone (connected by wire or
                                Bluetooth) can be a huge plus. We
                                recommend:</span
                            >
                            <ul>
                                <li style="font-weight: 400">
                                    <a
                                        href="https://www.amazon.com/Logitech-Headset-H390-Noise-Cancelling/dp/B000UXZQ42/ref=psdc_3015405011_t3_B00L2JUJ68"
                                        ><b
                                            >Logitech USB Headset H390 with
                                            Noise Cancelling Mic</b
                                        ></a
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <a
                                        href="https://www.amazon.com/Education-1EDU-AE36WH-ITE-Headphone-Microphone-Single/dp/B00L2JUJ68/ref=sr_1_2?dchild=1&amp;keywords=Avid+Education+1EDU-AE36WH-+ITE+Headphone+with+Boom+Microphone%2C&amp;qid=1590542817&amp;sr=8-2"
                                        ><b
                                            >Avid Education 1EDU-AE36WH-ITE
                                            Headphone with Boom Microphone,
                                            Single Plug, White</b
                                        ></a
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h3>Browser</h3>
                    <p>
                        <span style="font-weight: 400"
                            >NAES Biennial 2020 will support the latest versions
                            of Chrome, Safari, Firefox, and Edge. Internet
                            Explorer 11 is not fully supported and you should
                            use a different browser.</span
                        >
                    </p>
                    <ul>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >If you plan to participate in NAES Biennial
                                Conference 2020 from a tablet or smartphone,
                                know that some features will be harder to
                                access, although watching sessions will work
                                just fine.</span
                            >
                        </li>
                    </ul>
                    <h2>
                        <b>Accessing NAES Biennial Conference 2020 Workshops</b>
                    </h2>
                    <ul>
                        <li style="list-style-type: none">
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >There’s a lot of tech going on. Please
                                        make sure you can access the workshop
                                        virtual rooms by clicking this link to
                                        visit the Help Desk. While there:</span
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li style="list-style-type: none">
                            <ul>
                                <li style="list-style-type: none">
                                    <ul>
                                        <li style="font-weight: 400">
                                            <span style="font-weight: 400"
                                                >Make sure you can view the
                                                videos.</span
                                            >
                                        </li>
                                        <li style="font-weight: 400">
                                            <span style="font-weight: 400"
                                                >Make sure you have given your
                                                browser permission to use your
                                                speaker and microphone for the
                                                meeting.</span
                                            >
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <!-- .entry-content -->
            </article>
            <!-- #post-6541 -->
        </div>
    </div>
</template>
<script>
export default {
    name: "helpNaes"
};
</script>

<style lang="scss" scoped>
@import "../../styles/setup/colors";
@import "../../styles/views/help";
</style>
